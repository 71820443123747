import { socket } from "../App";
import { useContext, useState, useEffect, useRef } from "react";
import { GetData } from "./Handles/context";

function Chat() {
  const room = sessionStorage.getItem("room");
  const key = sessionStorage.getItem("key");
  const ivKey = sessionStorage.getItem("ivKey");
  const name = sessionStorage.getItem("name");
  const { messages, setMessages } = useContext(GetData);
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);

  function sendMessage() {
    socket.emit("send_message", {
      message: { content: message, sender: name },
      room: room,
      key: key,
      ivKey: ivKey,
    });
    setMessage("");
  }

  useEffect(() => {
    // Scroll to the bottom of the messages container when messages change
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  socket.on("sent_message", (data) => {
    console.log(data);
    setMessages(data.messages);
    console.log(data);
  });

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="w-full max-w-lg border border-gray-300 p-4 rounded-lg">
        <div className="chat max-h-96 overflow-y-auto">
          {messages?.map((message, index) => (
            <div key={index} className="message mb-2 flex items-start">
              <div className="message-body bg-gray-200 p-2 rounded-lg">
                <p className="message-sender text-xs text-gray-500 mb-1">
                  {message.sender}
                </p>
                <p className="message-text">{message.content}</p>
              </div>
            </div>
          ))}
          {/* This empty div serves as a reference point for scrolling */}
          <div ref={messagesEndRef}></div>
        </div>
        <div className="mt-4 flex">
          <input
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            className="p-2 border border-gray-300 rounded-l-lg flex-1"
          />
          <button
            onClick={sendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded-r-lg"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Chat;
