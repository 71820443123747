import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";

import { Manager } from "socket.io-client";
import Join_room from "./pages/login";
import Chat from "./pages/main";

const manager = new Manager("https://secure-chat-backend.onrender.com", {
  transports: ["websocket"],
  withCredentials: true,
  extraHeaders: {
    "my-custom-header": "abcd",
  },
});

export const socket = manager.socket("/"); // main namespace
const adminSocket = manager.socket("/admin");

socket.on("connect", () => {
  console.log("connect");
});

socket.emit("hello", "world", (response) => {
  console.log(response); // "got it"
});

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Join_room />} />
        <Route path={"/Chat"} element={<Chat />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
