import { socket } from "../App";
import { useContext, useState } from "react";
import { GetData } from "./Handles/context";
import { useNavigate } from "react-router-dom";

function JoinRoom() {
  const inputClass = "p-4 border border-gray-300 mt-2 block w-full"; // Added block and full width to inputs
  const [room, setRoom] = useState("");
  const [key, setKey] = useState("");
  const [ivKey, setIvKey] = useState("");
  const [name, setName] = useState("");
  const { messages, setMessages } = useContext(GetData);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  socket.on("room_joined", (validation, res) => {
    console.log(res);
    if (validation) {
      sessionStorage.clear();
      sessionStorage.setItem("room", res.room);
      sessionStorage.setItem("key", key);
      sessionStorage.setItem("ivKey", ivKey);
      sessionStorage.setItem("name", name);
      setMessages(res.messages);
      console.log(res.messages);
      navigate("/Chat");
    } else {
      console.log(res);
      setError(res.err || "Unknown error occurred"); // Set error message
    }
  });

  function handleSubmit() {
    setError(null); // Reset error state
    socket.emit("join_room", { room, key, ivKey });
    console.log(`Room ${room} requested`);
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-md">
        {error && (
          <div className="bg-red-200 text-red-700 text-center font-semibold py-2 px-4 rounded mb-4">
            {error}
          </div>
        )}
        <h2 className="text-center text-2xl font-bold mb-4">
          Fill the Details to Login
        </h2>
        <input
          type="text"
          placeholder="Room"
          value={room}
          onChange={(e) => setRoom(e.target.value)}
          className={`${inputClass} ${error && "border-red-500"}`} // Conditionally apply red border if error exists
        />
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={`${inputClass} mt-2 ${error && "border-red-500"}`} // Conditionally apply red border if error exists
        />
        <input
          type="text"
          placeholder="Key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
          className={`${inputClass} mt-2 ${error && "border-red-500"}`} // Conditionally apply red border if error exists
        />
        <input
          type="text"
          placeholder="IV Key"
          value={ivKey}
          onChange={(e) => setIvKey(e.target.value)}
          className={`${inputClass} mt-2 ${error && "border-red-500"}`} // Conditionally apply red border if error exists
        />
        <button
          onClick={handleSubmit}
          className={`bg-blue-500 text-white font-bold py-2 px-4 rounded mt-2 ${inputClass}`}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default JoinRoom;
