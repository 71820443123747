import {createContext, useState} from "react";


export const GetData = createContext({})

const GetDataProvider = ({children}) => {
    const [room,setRoom] = useState({});
    const [messages, setMessages] = useState([])


    return <GetData.Provider value={{room, setRoom, messages, setMessages}}>
        {children}
    </GetData.Provider>

}
export default GetDataProvider;